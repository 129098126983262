@font-face {
  font-family: "Bitter";
  src: url("../assets/font/Bitter-Regular.ttf"); }

@font-face {
  font-family: "Bitter";
  font-weight: bold;
  src: url("../assets/font/Bitter-Bold.ttf"); }

body {
  padding: 0;
  margin: 0;
  background: #0a0815 url("../assets/images/bg/bg-grain00001.png") no-repeat center/cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 1px);
  will-change: background;
  image-rendering: crisp-edges; }

h1 {
  color: #e1505a; }

a {
  color: #fff;
  text-decoration: none; }

button {
  font-family: "Bitter", mono, serif;
  font-size: 20px;
  border: 2px solid white;
  padding: 10px 20px;
  background: none;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;
  font-weight: bold;
  background: #0a0815; }
  button:before {
    content: '';
    width: 0px;
    height: 101%;
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%) skewX(-20deg) translateZ(0);
    background: #fff;
    top: 0;
    transition: 0.7s width;
    will-change: transform;
    backface-visibility: hidden; }
  button:hover {
    transition: 1s width;
    color: #000; }
    button:hover:before {
      width: 120%; }
  button.secondary {
    background: transparent;
    color: #e1505a;
    border: 0;
    padding: 10px 0; }
    button.secondary:before {
      display: none; }
  button.choice-button {
    opacity: 0;
    pointer-events: none;
    transform: scale(1.2, 1.2) translateZ(0);
    margin-top: 20px;
    backface-visibility: hidden; }
    button.choice-button__display {
      opacity: 1;
      pointer-events: auto;
      transition: 1s opacity, 1s transform;
      transform: scale(1, 1) translateZ(0); }
    button.choice-button__l12 {
      margin-left: 12px; }
    @media (min-width: 1180px) {
      button.choice-button__l20 {
        margin-left: 20px; } }

nav {
  z-index: 9999;
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  display: flex;
  align-items: flex-start; }
  nav > a {
    pointer-events: auto;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    margin: 10px; }
    nav > a.information {
      background: url("../assets/images/icons/information.png") no-repeat center/cover; }

@keyframes bg-intro {
  0%, 100% {
    background-image: url("../assets/images/bg/bg-grain00001.png"); }
  25% {
    background-image: url("../assets/images/bg/bg-grain00002.png"); }
  50% {
    background-image: url("../assets/images/bg/bg-grain00003.png"); }
  75% {
    background-image: url("../assets/images/bg/bg-grain00004.png"); } }

.red-skew {
  z-index: 1;
  position: absolute;
  bottom: -300px;
  height: 300px;
  left: 50%;
  width: 600px;
  transform: translateX(-50%) translateZ(0);
  transition: 10s bottom;
  will-change: bottom, clip-path;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
  .red-skew__animate {
    animation: 2s 4s clip-path-red-skew forwards linear;
    bottom: calc(-300px + 25%); }

@keyframes clip-path-red-skew {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%); } }

.line {
  opacity: 0; }
  .line__display {
    opacity: 1; }
  .line__horizontal {
    width: 0px;
    z-index: 0;
    height: 29px;
    background: url("../assets/images/line__horizontal.png") repeat-x center; }
    .line__horizontal__middle {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateZ(0) translateY(-50%); }
  .line__vertical {
    height: 0px;
    z-index: 0;
    width: 29px;
    background: url("../assets/images/line__vertical.png") repeat-x center; }
    .line__vertical__middle {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateZ(0) translateX(-50%);
      height: 100%; }
    .line__vertical__from-middle {
      top: calc(50% - 14.5px); }
    .line__vertical__to-middle {
      top: 0; }

.graphic-menu {
  position: absolute;
  top: calc(50% + 10px);
  left: 50%; }
  .graphic-menu ul {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .graphic-menu ul li {
      width: 50px;
      height: 50px;
      margin: 5px;
      list-style: none; }
      .graphic-menu ul li:first-child {
        margin-left: 0; }
      .graphic-menu ul li.animate > img {
        animation: graphic-menu-animate 2s linear infinite; }
      .graphic-menu ul li.active > img {
        border: 2px solid #fff;
        box-sizing: border-box;
        border-radius: 100%; }
      .graphic-menu ul li img {
        cursor: pointer;
        width: 50px; }

.graphic {
  width: 40%;
  height: 40%;
  position: absolute;
  will-change: none;
  backface-visibility: hidden;
  transition: 0.5s opacity; }
  .graphic__disabled {
    opacity: 0;
    pointer-events: none; }
  .graphic__rm {
    height: 90%;
    width: 40%;
    right: 5%;
    top: 5%; }
  .graphic__rt {
    width: 60%;
    height: 40%;
    left: 37%;
    top: 4%; }
  .graphic__rb-long {
    width: 75%;
    height: 40%;
    right: 2.5%;
    bottom: 35px; }
  .graphic__rt-sm {
    width: calc(44vh + 150px);
    height: 44vh;
    left: 52%;
    top: 2%; }
  .graphic__lb-sm {
    width: calc(44vh + 150px);
    height: 44vh;
    right: 52%;
    left: auto;
    bottom: 2%; }
  .graphic__rb-md {
    width: 40%;
    height: calc(44% - 80px);
    left: 50%;
    bottom: 35px; }
  .graphic__lt {
    width: 45%;
    height: 40%;
    left: 5%;
    top: 2.5%; }
  .graphic > svg {
    width: 100%;
    height: 100%; }

.graphic-group {
  cursor: pointer; }
  .graphic-group .graphic-text, .graphic-group .graphic-text-stroke {
    font-family: "Bitter", mono, serif;
    font-size: 20px;
    fill: #fff;
    pointer-events: none;
    opacity: 0; }
  .graphic-group .graphic-data {
    font-size: 30px;
    fill: #fff;
    font-family: "Bitter", mono, serif;
    pointer-events: none;
    opacity: 0; }
  .graphic-group circle {
    fill: transparent; }
  .graphic-group .graphic-image {
    transition: 1s; }
  .graphic-group:hover circle {
    fill: #fff !important;
    transition: 1s;
    stroke: white;
    stroke-width: 5px; }
  .graphic-group:hover .graphic-image {
    opacity: 0.5; }
  .graphic-group:hover .graphic-data {
    opacity: 1;
    fill: #0a0815;
    transition: 1s; }

.graphic-legend {
  font-family: "Bitter", mono, serif;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: calc(100% + 5px);
  opacity: 0.5;
  text-align: right;
  width: 100%; }

@keyframes graphic-menu-animate {
  0%, 100% {
    transform: scale(1, 1);
    opacity: 1; }
  50% {
    transform: scale(0.9, 0.9);
    opacity: 0.5; } }

.line-button {
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  backface-visibility: hidden; }
  .line-button__hide {
    opacity: 0;
    pointer-events: none; }
  .line-button:after {
    transform-origin: 50% 50%;
    content: '';
    border-radius: 100%;
    border: 2px solid white;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 43px;
    top: 43px;
    transform: translateZ(0);
    animation: 4s animate-button-line infinite linear; }
  .line-button:before {
    transform-origin: 50% 50%;
    left: 43px;
    top: 43px;
    content: '';
    border-radius: 100%;
    border: 2px solid white;
    position: absolute;
    width: 30px;
    height: 30px;
    transform: translateZ(0);
    animation: 4s 2s animate-button-line infinite linear; }
  .line-button__bottom {
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    top: 100%; }
  .line-button__right {
    left: 100%;
    transform: translate(-50%, -50%) translateZ(0);
    top: 50%; }
  .line-button__left {
    left: 0;
    transform: translate(-50%, -50%) translateZ(0);
    top: 50%; }

@keyframes animate-button-line {
  0% {
    transform: scale(1, 1) translateZ(0);
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    transform: scale(3, 3) translateZ(0);
    opacity: 0; } }

.glitch-text {
  color: #fff;
  position: relative; }
  .glitch-text:before {
    content: attr(text);
    position: absolute;
    animation: 1.2s infinite text-before-intro; }

@keyframes text-before-intro {
  0%, 100%, 50% {
    opacity: 1;
    transform: translate(0); }
  25% {
    opacity: 0.6;
    transform: translateX(-2px); }
  75% {
    opacity: 0.6;
    transform: translateX(2px); } }

.letter {
  display: inline-block;
  position: relative;
  will-change: transform;
  transform-origin: 50% 50% 25px;
  opacity: 0; }
  .letter.fade {
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .letter.space:before {
    content: ' '; }
  .letter.in {
    opacity: 1;
    transition: opacity 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.scene {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  overflow: hidden; }
  .scene__1 {
    transform: translateX(100%) translateZ(0); }
  .scene__2 {
    transform: translateX(100%) translateZ(0); }
  .scene__3, .scene__4 {
    transform: translateY(100%) translateZ(0); }
  .scene__5, .scene__6 {
    transform: translateX(-100%) translateZ(0); }
  .scene__active {
    transform: translate(0, 0) translateZ(0);
    left: 0;
    top: 0; }
  .scene__blur {
    filter: blur(20px); }
  .scene .scene-full {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 1s; }
    .scene .scene-full__black {
      background: black; }
    .scene .scene-full__visible {
      opacity: 1; }

.scene__0.scene__ended {
  transform: translateX(-100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }

.scene__0 #intro {
  z-index: 2;
  border-radius: 0;
  overflow: hidden;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff; }
  .scene__0 #intro.intro__remove {
    transition: 6s transform, 6s border-radius;
    transform: scale(0.5, 0.5) translateZ(0);
    border-radius: 50px;
    will-change: transform, border-radius;
    pointer-events: none;
    background-color: #0a0815;
    animation: 1s 5s forwards removeIntro; }
    .scene__0 #intro.intro__remove .intro-redline {
      will-change: transform, height, opacity, display;
      transition: 3s height linear, 6s transform;
      height: 0px;
      bottom: 0;
      top: auto;
      transform: scale(2) translate(-50%) translateZ(0); }
  .scene__0 #intro.intro__hide {
    transition: 2s opacity, 2s display;
    opacity: 0;
    display: none; }
  .scene__0 #intro .intro-pre {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    transition: 1s; }
    .scene__0 #intro .intro-pre img {
      width: 50%; }
    .scene__0 #intro .intro-pre .intro-button {
      margin-top: 30px;
      transition: 1s; }
    .scene__0 #intro .intro-pre__hide {
      pointer-events: none;
      opacity: 0; }
    .scene__0 #intro .intro-pre a {
      color: #ffffff;
      opacity: 0.5;
      font-family: "Bitter", mono, serif;
      font-size: 15px;
      display: block;
      margin-top: 20px;
      text-decoration: none; }
  .scene__0 #intro .intro-text-container {
    opacity: 0;
    pointer-events: none;
    transition: 1s; }
    .scene__0 #intro .intro-text-container__show {
      opacity: 1; }
  .scene__0 #intro .intro-text-item {
    display: none;
    font-size: 30px;
    font-family: "Bitter", mono, serif; }
  .scene__0 #intro .intro-redline {
    background: #e1505a;
    position: absolute;
    width: 10px;
    left: 50%;
    transform: translate(-50%) translateZ(0);
    height: 0%;
    top: 0;
    transition: 3s; }
    .scene__0 #intro .intro-redline__step-1 {
      height: 25%;
      transition: 3s; }
    .scene__0 #intro .intro-redline__step-2 {
      height: 50%;
      transition: 3s; }
    .scene__0 #intro .intro-redline__step-3 {
      height: 75%;
      transition: 3s; }
    .scene__0 #intro .intro-redline__step-4 {
      height: 100%;
      transition: 3s; }

.scene__0 .line__display {
  z-index: 2;
  left: 20vw;
  width: 80vw;
  transition: 5s width; }

@keyframes removeIntro {
  0% {
    opacity: 1;
    display: flex; }
  99% {
    display: flex; }
  100% {
    opacity: 0;
    display: none; } }

.inspector-container .inspector {
  width: 30vw;
  height: 80vh;
  background: url("../assets/images/inspector.png") no-repeat center bottom/auto 100%;
  animation: 0.3s bg-inspector infinite linear;
  left: 10vh;
  bottom: -30px;
  pointer-events: none;
  will-change: opacity, filter, transform;
  transform-origin: 100% 25%;
  backface-visibility: hidden;
  perspective: 1000;
  z-index: 2;
  position: absolute; }
  .inspector-container .inspector__default {
    opacity: 0;
    -filter: blur(20px);
    transform: scale(2) translateZ(0); }
  .inspector-container .inspector__show {
    transition: 6s opacity, 6s filter, 6s transform;
    opacity: 1;
    -filter: blur(0px);
    transform: scale(1) translateZ(0); }
  .inspector-container .inspector-text {
    font-family: "Bitter", mono, serif;
    color: #fff;
    position: absolute;
    top: 50%;
    width: 40%;
    line-height: 1.3em;
    word-spacing: 0.05em;
    font-size: 22px;
    background-position-x: -100%;
    pointer-events: none;
    opacity: 0;
    z-index: 5; }
    .inspector-container .inspector-text__appear {
      opacity: 1;
      transition: 3s opacity; }
      .inspector-container .inspector-text__appear .text-style {
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        color: transparent;
        background-image: url("../assets/images/text-effect-bruit.gif");
        transition: 1s opacity; }
        .inspector-container .inspector-text__appear .text-style:not(:first-child) {
          margin-top: 15px; }
        .inspector-container .inspector-text__appear .text-style a {
          border-bottom: 3px solid #e1505a;
          font-weight: bold;
          cursor: pointer;
          pointer-events: auto; }
          .inspector-container .inspector-text__appear .text-style a:hover {
            border-bottom-color: #fff; }
    .inspector-container .inspector-text__alb {
      left: 5%;
      width: 40%;
      transform: translateY(40px); }
    .inspector-container .inspector-text__alt {
      left: 5%;
      width: 40%;
      bottom: 50%;
      top: auto;
      transform: translateY(-40px); }
    .inspector-container .inspector-text__art {
      left: 50%;
      max-width: 550px;
      width: 40%;
      bottom: 50%;
      top: auto;
      transform: translateY(-40px); }
    .inspector-container .inspector-text__almt {
      left: 30px;
      width: 40%;
      top: 30px; }
    .inspector-container .inspector-text__mtl {
      left: 50%;
      width: 42%;
      max-width: 430px;
      top: 25%;
      text-align: right;
      transform: translateX(calc(-100% - 60px)) translateY(-50%) translateZ(0); }
    .inspector-container .inspector-text__mbr {
      left: calc(50% + 60px);
      width: 42%;
      top: 75%;
      max-width: 430px;
      transform: translateY(-50%) translateZ(0); }
    .inspector-container .inspector-text__br-sm {
      transform: translateY(calc(-100% - 40px));
      right: 2%;
      left: auto;
      width: calc(46% - 120px); }
    .inspector-container .inspector-text__rt {
      transform: translateY(calc(-100% - 40px)) translateZ(0);
      left: 40%; }
    .inspector-container .inspector-text__rb {
      transform: translateY(30px);
      left: 40%; }

@keyframes bg-inspector {
  0%, 100% {
    background-image: url("../assets/images/inspector/grain-animation0000.png"); }
  33% {
    background-image: url("../assets/images/inspector/grain-animation0001.png"); }
  66% {
    background-image: url("../assets/images/inspector/grain-animation0002.png"); } }

.scene__1.scene__ended {
  transform: translateX(-100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }

.scene__1.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }

.scene__1 .phone {
  z-index: 2;
  width: 20vw;
  height: 30vw;
  background: url("../assets/images/phone/portable-animation0000.png") no-repeat center/auto 100%;
  animation: 0.4s phone-animation infinite linear;
  top: 50%;
  left: 10%;
  transform: translateY(-50%) rotate(5deg) translateZ(0);
  position: absolute;
  backface-visibility: hidden; }
  .scene__1 .phone .phone-character {
    background: url("../assets/images/phone/bonhomme.png") no-repeat center/100% auto;
    position: absolute;
    left: 25%;
    width: 50%;
    height: 100%;
    animation: 1s phone-character-animation infinite;
    backface-visibility: hidden; }

.scene__1 .line {
  opacity: 1;
  width: 100%; }

@keyframes phone-animation {
  0%, 100% {
    background-image: url("../assets/images/phone/portable-animation0000.png"); }
  33% {
    background-image: url("../assets/images/phone/portable-animation0001.png"); }
  66% {
    background-image: url("../assets/images/phone/portable-animation0002.png"); } }

/*@keyframes phone-character-animation {
  50% {
    filter: saturate(1);
  }
  0% {
    filter: saturate(0);
  }
}*/
.scene__2.scene__ended {
  transform: translateY(-100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }
  .scene__2.scene__ended .line[line='1'] {
    opacity: 1;
    width: calc(50% + 14.5px); }
  .scene__2.scene__ended .line[line='2'] {
    opacity: 1;
    height: calc(50% + 14.5px); }

.scene__2 .line {
  opacity: 1; }
  .scene__2 .line[line='2'] {
    height: 0; }

.scene__2.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }
  .scene__2.scene__active .line {
    transition: 2s linear; }
    .scene__2.scene__active .line[line='1'] {
      opacity: 1;
      width: calc(50% + 14.5px); }
    .scene__2.scene__active .line[line='2'] {
      transition-delay: 2s;
      opacity: 1;
      height: calc(50% + 14.5px); }

.scene__2 .social-screen > img {
  opacity: 0;
  transition: 1s opacity;
  max-height: 40%;
  max-width: 30%; }

.scene__2 .social-screen__show {
  opacity: 1 !important; }

.scene__2 .social-screen-fb {
  position: absolute;
  left: 5%;
  top: 25%;
  transform: rotate(3deg) translateY(-50%);
  border: 2px solid #fff; }

.scene__2 .social-screen-twitter {
  position: absolute;
  left: 38%;
  top: 25%;
  transform: rotate(-2deg) translateY(-50%);
  border: 2px solid #fff; }

.scene__2 .social-screen-insta {
  position: absolute;
  max-width: 40% !important;
  max-height: 50% !important;
  right: 5%;
  top: 75%;
  transform: rotate(-6deg) translateY(-50%);
  border: 2px solid #fff; }

.scene__2 .social-screen-reddit {
  position: absolute;
  right: 5%;
  top: 35%;
  z-index: 2;
  border: 2px solid #fff;
  transform: rotate(4deg) translateY(-50%); }

.scene__3.scene__ended {
  transform: translateY(-100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }
  .scene__3.scene__ended .line {
    opacity: 1;
    height: 100%; }

.scene__3 .line {
  opacity: 1;
  top: 0;
  height: 0; }

.scene__3.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }
  .scene__3.scene__active .line {
    transition: 3s linear;
    opacity: 1;
    height: 100%; }

.scene__4.scene__ended {
  transform: translateX(100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }
  .scene__4.scene__ended .line {
    opacity: 1; }
    .scene__4.scene__ended .line[line='1'] {
      height: calc(50% + 14.5px); }
    .scene__4.scene__ended .line[line='2'] {
      opacity: 1;
      width: calc(50% + 14.5px); }

.scene__4 .line[line='1'] {
  opacity: 1;
  height: 0; }

.scene__4 .line[line='2'] {
  opacity: 1;
  right: 50%;
  left: auto;
  width: 0; }

.scene__4.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }
  .scene__4.scene__active .line {
    transition: 2s linear; }
    .scene__4.scene__active .line[line='1'] {
      opacity: 1;
      height: calc(50% + 14.5px); }
    .scene__4.scene__active .line[line='2'] {
      transition-delay: 2s;
      opacity: 1;
      width: calc(50% + 14.5px); }

.scene__4 .folder-maker {
  transition: opacity 0.5s;
  width: 45%;
  right: 2.5%;
  bottom: calc(50% - 14.5px);
  position: absolute;
  z-index: 10;
  background: url("../assets/images/windows/ecran-windows.png") repeat center/100%; }
  .scene__4 .folder-maker__hide {
    opacity: 0; }
  .scene__4 .folder-maker .folder-maker-name {
    position: absolute;
    color: #fff;
    font-family: "Bitter", mono, serif;
    font-size: 17px;
    font-weight: bold;
    top: 4px;
    left: 5px; }
  .scene__4 .folder-maker:before {
    content: '';
    background: url("../assets/images/windows/ecran-windows-bar.png") center/100%;
    width: 100%;
    height: 35px;
    position: relative;
    display: block; }
  .scene__4 .folder-maker:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 10px;
    height: 25px;
    width: 100px;
    background: url("../assets/images/windows/ecran-windows-logo.png") no-repeat center/100%; }
  .scene__4 .folder-maker .folder-line {
    font-family: "Bitter", mono, serif;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px;
    box-sizing: border-box; }
    .scene__4 .folder-maker .folder-line .folder-line-icon {
      width: 20px;
      height: 20px;
      background: url("../assets/images/icons/icon-faux.png") no-repeat center/auto 100%;
      margin: 5px; }
    .scene__4 .folder-maker .folder-line .folder-line-elements {
      text-align: right;
      margin-left: auto;
      margin-right: 5px; }
    .scene__4 .folder-maker .folder-line .folder-line-name div {
      display: none; }
    .scene__4 .folder-maker .folder-line .folder-line-name select {
      display: block; }
    .scene__4 .folder-maker .folder-line__verified {
      background: rgba(88, 225, 225, 0.15); }
      .scene__4 .folder-maker .folder-line__verified .folder-line-icon {
        background: url("../assets/images/icons/icon-correct.png") no-repeat center/auto 100%; }
      .scene__4 .folder-maker .folder-line__verified .folder-line-elements {
        opacity: 0.5; }
      .scene__4 .folder-maker .folder-line__verified .folder-line-name select {
        display: none; }
      .scene__4 .folder-maker .folder-line__verified .folder-line-name div {
        opacity: 0.7;
        display: block; }

.scene__5.scene__ended {
  transform: translateX(100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }
  .scene__5.scene__ended .line {
    width: 100%; }

.scene__5 .line {
  opacity: 1;
  width: 0%;
  right: 0;
  left: auto; }

.scene__5.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }
  .scene__5.scene__active .line {
    transition: 4s linear;
    width: 100%;
    right: 0; }

.scene__5 .phone {
  z-index: 2;
  width: 20vw;
  height: 30vw;
  background: url("../assets/images/phone/portable-nude-animation0000.png") no-repeat center/auto 100%;
  animation: 0.4s phone-animation-nude infinite linear;
  top: 50%;
  left: 15vw;
  transform: translateY(-50%) rotate(5deg) translateZ(0);
  position: absolute;
  backface-visibility: hidden; }

@keyframes phone-animation-nude {
  0%, 100% {
    background-image: url("../assets/images/phone/portable-nude-animation0000.png"); }
  33% {
    background-image: url("../assets/images/phone/portable-nude-animation0001.png"); }
  66% {
    background-image: url("../assets/images/phone/portable-nude-animation0002.png"); } }

.scene__6.scene__ended {
  transform: translateX(-100%);
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 1; }

.scene__6 .line {
  opacity: 1;
  width: 0%;
  right: 0;
  left: auto;
  z-index: 2; }

.scene__6 .door-red {
  z-index: 3;
  position: absolute;
  width: 130px;
  height: 393px;
  top: 50%;
  left: 50%;
  transform: translate(calc(-224px), -50%) translateZ(0); }

.scene__6 .door {
  z-index: 0;
  position: absolute;
  width: 227px;
  height: 393px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0); }

.scene__6 .door-shadow {
  position: absolute;
  width: 779px;
  height: 437px;
  top: 50%;
  left: 50%;
  transform: translate(-397px, 163px) translateZ(0);
  background: no-repeat center / contain;
  animation: 0.5s bg-ombre infinite linear; }

.scene__6.scene__active {
  transition: 5s cubic-bezier(0.2, 0, 0, 1.02);
  z-index: 2; }
  .scene__6.scene__active .line {
    transition: 3s linear;
    width: calc(50% + 105px);
    right: 0;
    left: auto; }

.scene__6 .links {
  width: 30%;
  left: 60%;
  position: absolute;
  top: 50%;
  transform: translate(0, 30px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 1s;
  pointer-events: auto; }
  .scene__6 .links .link-header {
    width: 100%;
    padding: 5px 10px;
    font-size: 24px;
    letter-spacing: 0.04em;
    font-weight: bold;
    color: #e1505a;
    font-family: "Bitter", mono, serif; }
  .scene__6 .links.links__hidden {
    opacity: 0;
    pointer-events: none; }
  .scene__6 .links .link-item {
    width: 50%;
    padding: 10px;
    box-sizing: border-box; }
    .scene__6 .links .link-item img {
      width: 100%; }

@keyframes bg-ombre {
  0%, 100% {
    background-image: url("../assets/images/final/ombre-1.png"); }
  33% {
    background-image: url("../assets/images/final/ombre-2.png"); }
  66% {
    background-image: url("../assets/images/final/ombre-3.png"); } }

#credits {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  transition: 0.5s opacity;
  top: 0;
  opacity: 1; }
  #credits.credits__hide {
    opacity: 0;
    transition: 0.5s opacity;
    pointer-events: none; }
  #credits .credit-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    cursor: pointer; }
  #credits .credit-container {
    position: absolute;
    margin: 50px;
    background: #0a0815 no-repeat center/cover;
    animation: 0.5s bg-intro infinite linear;
    border-radius: 50px;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Bitter", mono, serif; }
    #credits .credit-container a {
      color: #fff;
      text-decoration: none;
      border-bottom: 3px solid #e1505a;
      font-weight: bold; }
      #credits .credit-container a:hover {
        border-bottom-color: #fff; }
    #credits .credit-container .credit-container-logos {
      display: flex;
      align-items: center;
      justify-content: center; }
      #credits .credit-container .credit-container-logos a {
        border-bottom: 0; }
      #credits .credit-container .credit-container-logos img {
        width: 150px; }
        #credits .credit-container .credit-container-logos img:not(:first-child) {
          margin-left: 20px; }
    #credits .credit-container .credit-container-sources ul {
      font-size: 15px; }
    #credits .credit-container .credit-container-student {
      margin-top: 20px;
      font-size: 16px; }
    #credits .credit-container .credit-separator {
      width: 100%;
      z-index: 0;
      height: 29px;
      background: url("../assets/images/line__horizontal.png") repeat-x center;
      margin: 20px 0; }

.bb {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  font-family: "Bitter", mono, serif;
  font-size: 18px; }
  .bb svg {
    backface-visibility: hidden;
    will-change: height, width;
    transform: translateZ(0); }
    .bb svg .bb-legend-item {
      fill: #fff;
      font-size: 16px; }
    .bb svg path, .bb svg line {
      fill: none;
      stroke: #fff;
      stroke-width: 4px; }
    .bb svg .bb-chart-arc path, .bb svg .bb-chart-arc line, .bb svg .bb-chart-bars .bb-shapes path, .bb svg .bb-chart-bars .bb-shapes line {
      stroke-width: 0; }
    .bb svg .bb-axis path {
      fill: none;
      stroke: #fff;
      stroke-width: 2px; }
    .bb svg .bb-axis .tick {
      fill: #fff;
      font-size: 15px; }

.bb text, .bb .bb-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.bb-legend-item-tile,
.bb-xgrid-focus,
.bb-ygrid,
.bb-event-rect,
.bb-bars path {
  shape-rendering: crispEdges; }

.bb-chart-arc path {
  stroke: #fff; }

.bb-chart-arc text {
  fill: #fff;
  font-size: 17px; }

/*-- Axis --*/
/*-- Grid --*/
.bb-grid line {
  stroke: #aaa; }

.bb-grid text {
  fill: #aaa; }

.bb-xgrid, .bb-ygrid {
  stroke-dasharray: 3 3; }

/*-- Text on Chart --*/
.bb-text.bb-empty {
  fill: #808080;
  font-size: 2em; }

/*-- Line --*/
.bb-line {
  stroke-width: 1px; }

/*-- Point --*/
.bb-circle._expanded_ {
  stroke-width: 1px;
  stroke: white; }

.bb-selected-circle {
  fill: white;
  stroke-width: 2px; }

/*-- Bar --*/
.bb-bar {
  stroke-width: 0; }

.bb-bar._expanded_ {
  fill-opacity: 0.75; }

/*-- Focus --*/
.bb-target.bb-focused {
  opacity: 1; }

.bb-target.bb-focused path.bb-line, .bb-target.bb-focused path.bb-step {
  stroke-width: 2px; }

.bb-target.bb-defocused {
  opacity: 0.3 !important; }

/*-- Region --*/
.bb-region {
  fill: steelblue;
  fill-opacity: .1; }

/*-- Zoom region --*/
.bb-zoom-brush {
  fill-opacity: .1; }

/*-- Brush --*/
.bb-brush .extent {
  fill-opacity: .1; }

/*-- Select - Drag --*/
/*-- Legend --*/
.bb-legend-item {
  font-size: 12px; }

.bb-legend-item-hidden {
  opacity: 0.15; }

.bb-legend-background {
  opacity: 1;
  fill: white;
  stroke: lightgray;
  stroke-width: 1; }

/*-- Title --*/
.bb-title {
  font: 14px sans-serif; }

/*-- Tooltip --*/
.bb-tooltip-container {
  z-index: 10; }

.bb-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;
  font-size: 15px;
  font-family: "Bitter", mono, serif;
  border-radius: 2px; }
  .bb-tooltip tr {
    border: none; }
  .bb-tooltip th {
    font-size: 16px;
    padding: 4px 6px 2px;
    text-align: left;
    color: #000;
    font-weight: bold; }
  .bb-tooltip td {
    font-size: 13px;
    padding: 4px 6px; }
    .bb-tooltip td.value {
      color: #e1505a;
      text-align: right; }
    .bb-tooltip td > span, .bb-tooltip td > svg {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 6px; }

/*-- Area --*/
.bb-area {
  stroke-width: 0;
  opacity: 0.2; }

/*-- Arc --*/
.bb-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em; }

.bb-chart-arcs .bb-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none; }

.bb-chart-arcs .bb-chart-arcs-gauge-unit {
  fill: #fff;
  font-size: 16px; }

.bb-chart-arcs .bb-chart-arcs-gauge-max {
  fill: #777; }

.bb-chart-arcs .bb-chart-arcs-gauge-min {
  fill: #777; }

.bb-chart-arc .bb-gauge-value {
  fill: #fff; }

/*-- Radar --*/
.bb-chart-radars .bb-levels polygon {
  fill: none;
  stroke: #848282;
  stroke-width: .5px; }

.bb-chart-radars .bb-levels text {
  fill: #848282; }

.bb-chart-radars .bb-axis line {
  stroke: #848282;
  stroke-width: .5px; }

.bb-chart-radars .bb-axis text {
  font-size: 1.15em;
  cursor: default; }

.bb-chart-radars .bb-shapes polygon {
  fill-opacity: .2;
  stroke-width: 1px; }

/*-- Button --*/
.bb-button {
  position: absolute;
  top: 10px;
  right: 10px; }

.bb-button .bb-zoom-reset {
  font-size: 11px;
  border: solid 1px #ccc;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer; }

/*# sourceMappingURL=billboard.css.map*/
